import { defaultApiState } from '@/store/types/general.types';
import { AxiosError } from 'axios';
import { adminState } from '../admin/admin.state';
import { JobResultData, JobResultDataState } from './job-result-data.types';

export const jobResultDataMutations: {
  GET_JOB_RESULT_DATA_SUCCESS(
    state: JobResultDataState,
    data: JobResultData
  ): void;
  GET_JOB_RESULT_DATA_LOADING(state: JobResultDataState): void;
  GET_JOB_RESULT_DATA_ERROR(state: JobResultDataState, error: AxiosError): void;
  VALIDATE_USER_OR_MEMBER_LIST_SUCCESS(state: JobResultDataState): void;
  VALIDATE_USER_OR_MEMBER_LIST_LOADING(state: JobResultDataState): void;
  VALIDATE_USER_OR_MEMBER_LIST_ERROR(
    state: JobResultDataState,
    error: AxiosError
  ): void;
} = {
  GET_JOB_RESULT_DATA_SUCCESS(state: JobResultDataState, data: JobResultData) {
    state.apiState.getJobResultData = {
      ...defaultApiState,
      success: true
    };
    state.jobResultData = data;
  },
  GET_JOB_RESULT_DATA_LOADING(state: JobResultDataState) {
    state.apiState.getJobResultData = {
      ...defaultApiState,
      loading: true
    };
  },
  GET_JOB_RESULT_DATA_ERROR(state: JobResultDataState, error: AxiosError) {
    state.apiState.getJobResultData = {
      ...defaultApiState,
      error
    };
  },
  VALIDATE_USER_OR_MEMBER_LIST_SUCCESS(state: JobResultDataState) {
    state.apiState.createValidationJob = {
      ...defaultApiState,
      success: true
    };
    adminState.groupLicenses = undefined;
  },
  VALIDATE_USER_OR_MEMBER_LIST_LOADING(state: JobResultDataState) {
    state.apiState.createValidationJob = {
      ...defaultApiState,
      loading: true
    };
  },
  VALIDATE_USER_OR_MEMBER_LIST_ERROR(
    state: JobResultDataState,
    error: AxiosError
  ) {
    state.apiState.createValidationJob = {
      ...defaultApiState,
      error
    };
  }
};
