import {
  ValidateImportedMemberListPayload,
  ValidateImportedUserListPayload
} from '@/store/modules/job-result-data/job-result-data.types';
import { GetJobResultDataPayload } from '../store/modules/job-result-data/job-result-data.types';
import { axios } from './axios';

export const jobResultDataApi = {
  getJobResultData(payload: GetJobResultDataPayload) {
    return axios.get('/job-result-data', {
      params: payload
    });
  },
  createImportedListValidationJob(
    payload: ValidateImportedMemberListPayload | ValidateImportedUserListPayload
  ) {
    return axios.post(`/job-result-data/imported-list-validation`, payload);
  }
};
