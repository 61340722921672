import { RootState } from '@/store/store';
import { ModuleTree } from 'vuex';
import { jobResultDataActions } from './job-result-data.actions';
import { jobResultDataMutations } from './job-result-data.mutations';
import { jobResultDataState } from './job-result-data.state';

export default {
  namespaced: true,
  state: jobResultDataState,
  actions: jobResultDataActions,
  mutations: jobResultDataMutations
} as ModuleTree<RootState>;
