import { JobResultDataState } from '@/store/modules/job-result-data/job-result-data.types';
import { defaultApiState } from '@/store/types/general.types';

export const jobResultDataState: JobResultDataState = {
  apiState: {
    getJobResultData: defaultApiState,
    createValidationJob: defaultApiState
  },
  jobResultData: undefined
};
