import { jobResultDataApi } from '@/api/job-result-data.api';
import { Store } from 'vuex';
import {
  GetJobResultDataPayload,
  JobResultDataState,
  ValidateImportedMemberListPayload,
  ValidateImportedUserListPayload
} from './job-result-data.types';

export const jobResultDataActions = {
  async getJobResultData(
    store: Store<JobResultDataState>,
    payload: GetJobResultDataPayload
  ): Promise<void> {
    try {
      store.commit('GET_JOB_RESULT_DATA_LOADING');
      const { data } = await jobResultDataApi.getJobResultData(payload);
      store.commit('GET_JOB_RESULT_DATA_SUCCESS', data);
    } catch (error) {
      store.commit('GET_JOB_RESULT_DATA_ERROR', error);
    }
  },
  async createImportedListValidationJob(
    store: Store<JobResultDataState>,
    payload: ValidateImportedMemberListPayload | ValidateImportedUserListPayload
  ): Promise<void> {
    try {
      store.commit('VALIDATE_USER_OR_MEMBER_LIST_LOADING');

      const { data } = await jobResultDataApi.createImportedListValidationJob(
        payload
      );

      store.commit('VALIDATE_USER_OR_MEMBER_LIST_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('VALIDATE_USER_OR_MEMBER_LIST_ERROR', error);
      throw error;
    }
  }
};
