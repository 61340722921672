import { adminApi } from '@/api/admin.api';
import {
  ExportDeleteGroupMembersCsvPayload,
  ExportGroupMembersCsvPayload,
  FilteredGroupPayload,
  FilteredSubGroupPayload,
  GetFilteredUserListPayload,
  GetGroupProductsPayload,
  GetInvitedUserPayload,
  GroupAttributeOrder,
  GroupType
} from '@/store/modules/admin/types/admin.types';
import { Store } from 'vuex';
import {
  CreateThirdPartyUserPayload,
  GetThirdPartyUsersRequestPayload,
  UpdateThirdPartyUserPayload
} from '../../../views/AdminThirdPartyManagement/types/admin-third-party-management.types';
import { ProfileState, ProfileUpdatePayload } from '../profile/profile.state';
import {
  AddTermsAndConditionPayload,
  AdminState,
  BulkInviteUserToProductPayload,
  GetInvitationsPayload,
  GetTermsAndConditionsPayload,
  InviteUserToProductPayload,
  UpdateTermsAndConditionPayload
} from './types/admin.types';
import {
  AddressFilterOption,
  FilteredGroupTemplatesPayload,
  GroupLevelAttributeValuePayload,
  GroupTemplatePayload
} from './types/group-level-attribute.types';
import {
  CreateGroupLicenseRequestPayload,
  GetGroupLicensePayload
} from './types/group-license.types';
import {
  CreateGroupProductRequestPayload,
  UpdateGroupProductRequestPayload
} from './types/group-product.types';
import { GetGroupTypesPayload } from './types/group-type.types';
import {
  GetGroupUserAttributeTemplatePayload,
  NewGroupUserAttributeTemplatePayload,
  UpdateGroupUserAttributeTemplatePayload
} from './types/group-user-attribute-template.types';
import {
  BulkUpdateMemberGroupUserAttributeValuePayload,
  GetGroupUserAttributeListPayload,
  GetUploadImageSignedUrlPayload,
  GroupUserAttributePayload,
  UpdateGroupsUserAttributePayload,
  UpdateGroupsWithAttributePayload
} from './types/group-user-attribute.types';
import {
  AddMembersToGroupRequestPayload,
  CreateNewGroupRequestPayload,
  DeleteGroupMemberRequestPayload,
  ResendEmailNotificationToGroupMemberPayload,
  UpdateGroupMemberRequestPayload,
  UpdateGroupRequestPayload
} from './types/group.types';
import {
  GetSubscriptionsPayload,
  UpdateUserSubscriptionStatusPayload
} from './types/user-subscription.types';

export const adminActions = {
  async inviteNewUserToProduct(
    store: Store<AdminState>,
    payload: InviteUserToProductPayload
  ) {
    try {
      store.commit('INVITE_NEW_USER_TO_PRODUCT_LOADING');

      const { data } = await adminApi.inviteNewUserToProduct(payload);

      store.commit('INVITE_NEW_USER_TO_PRODUCT_SUCCESS', data);
    } catch (error) {
      store.commit('INVITE_NEW_USER_TO_PRODUCT_ERROR', error);
    }
  },
  async bulkInviteNewUserToProduct(
    store: Store<AdminState>,
    payload: BulkInviteUserToProductPayload
  ) {
    try {
      store.commit('BULK_INVITE_NEW_USER_TO_PRODUCT_LOADING');

      await adminApi.bulkInviteNewUserToProduct(payload);

      store.commit('BULK_INVITE_NEW_USER_TO_PRODUCT_SUCCESS');
    } catch (error) {
      store.commit('BULK_INVITE_NEW_USER_TO_PRODUCT_ERROR', error);
    }
  },
  async generateInvitationLinks(
    store: Store<AdminState>,
    payload: BulkInviteUserToProductPayload
  ) {
    try {
      store.commit('GENERATE_INVITATION_LINKS_LOADING');

      const { data } = await adminApi.generateInvitationLinks(payload);

      store.commit('GENERATE_INVITATION_LINKS_SUCCEES');

      return data;
    } catch (error) {
      store.commit('GENERATE_INVITATION_LINKS_ERROR', error);
    }
  },
  async getUsers(store: Store<AdminState>, params: GetInvitedUserPayload) {
    try {
      store.commit('GET_INVITED_USERS_LOADING');

      const { data } = await adminApi.getUsers(params);

      store.commit('GET_INVITED_USERS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_INVITED_USERS_ERROR', error);
    }
  },
  async removeUserInvitation(store: Store<AdminState>, id: number) {
    try {
      store.commit('REMOVE_USER_INVITATION_LOADING');

      await adminApi.removeInvitation(id);

      store.commit('REMOVE_USER_INVITATION_SUCCESS');
    } catch (error) {
      store.commit('REMOVE_USER_INVITATION_ERROR', error);
    }
  },
  async removeInvitation(store: Store<AdminState>, id: number) {
    try {
      store.commit('REMOVE_INVITATION_LOADING');

      await adminApi.removeInvitation(id);

      store.commit('REMOVE_INVITATION_SUCCESS');
    } catch (error) {
      store.commit('REMOVE_INVITATION_ERROR', error);
    }
  },

  async getUserInvitations(
    store: Store<AdminState>,
    { email }: { email: string }
  ) {
    try {
      store.commit('GET_USER_INVITATIONS_LOADING');

      const { data } = await adminApi.getUserInvitations(email);

      store.commit('GET_USER_INVITATIONS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_USER_INVITATIONS_ERROR', error);
    }
  },
  async getInvitations(
    store: Store<AdminState>,
    params: GetInvitationsPayload
  ) {
    try {
      store.commit('GET_INVITATIONS_LOADING');

      const { data } = await adminApi.getInvitations(params);

      store.commit('GET_INVITATIONS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_INVITATIONS_ERROR', error);
    }
  },
  async getSubscriptions(
    store: Store<AdminState>,
    params: GetSubscriptionsPayload
  ) {
    try {
      store.commit('GET_SUBSCRIPTIONS_LOADING');

      const { data } = await adminApi.getSubscriptions(params);

      store.commit('GET_SUBSCRIPTIONS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_SUBSCRIPTIONS_ERROR', error);
    }
  },
  async toggleUser(
    store: Store<AdminState>,
    { userId, email }: { userId: number; email: string }
  ) {
    try {
      store.commit('DISABLE_USER_LOADING');
      await adminApi.toggleUser(userId, email);
      store.commit('DISABLE_USER_SUCCESS');
    } catch (error) {
      store.commit('DISABLE_USER_ERROR', error);
    }
  },
  async verifyUser(store: Store<AdminState>, userId: number) {
    try {
      store.commit('VERIFY_USER_LOADING');
      await adminApi.verifyUser(userId);
      store.commit('VERIFY_USER_SUCCESS');
    } catch (error) {
      store.commit('VERIFY_USER_ERROR', error);
    }
  },
  async resendUserInvitation(
    store: Store<AdminState>,
    { id, email }: { id: number; email: string }
  ) {
    try {
      store.commit('RESEND_USER_INVITATION_LOADING');

      await adminApi.resendUserInvitation(id, email);

      store.commit('RESEND_USER_INVITATION_SUCCESS');
    } catch (error) {
      store.commit('RESEND_USER_INVITATION_ERROR', error);
    }
  },
  async getTermsAndConditions(
    store: Store<AdminState>,
    params: GetTermsAndConditionsPayload
  ) {
    try {
      store.commit('GET_TERMS_AND_CONDITIONS_LOADING');

      const { data } = await adminApi.getTermsAndConditions(params);

      store.commit('GET_TERMS_AND_CONDITIONS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_TERMS_AND_CONDITIONS_ERROR', error);
    }
  },

  async getTermsAndConditionsByAppId(store: Store<AdminState>, appId: string) {
    try {
      store.commit('GET_TERMS_AND_CONDITIONS_APPLICATION_LOADING');

      const { data } = await adminApi.getTermsAndConditionsByAppID(appId);

      store.commit('GET_TERMS_AND_CONDITIONS_APPLICATION_SUCCESS', data);
    } catch (error) {
      store.commit('GET_TERMS_AND_CONDITIONS_APPLICATION_ERROR', error);
    }
  },

  async getTermsAndConditionsHistory(store: Store<AdminState>, tncId: number) {
    try {
      store.commit('GET_TERMS_AND_CONDITIONS_HISTORY_LOADING');

      const { data } = await adminApi.getTermsAndConditionsHistory(tncId);

      store.commit('GET_TERMS_AND_CONDITIONS_HISTORY_SUCCESS', data);
    } catch (error) {
      store.commit('GET_TERMS_AND_CONDITIONS_HISTORY_ERROR', error);
    }
  },

  async addTermsAndConditions(
    store: Store<AdminState>,
    payload: AddTermsAndConditionPayload
  ) {
    try {
      store.commit('ADD_TERMS_AND_CONDITIONS_LOADING');
      const { data } = await adminApi.addTermsAndConditions(payload);

      store.commit('ADD_TERMS_AND_CONDITIONS_SUCCESS', data);
    } catch (error) {
      store.commit('ADD_TERMS_AND_CONDITIONS_ERROR', error);
    }
  },
  async updateTermsAndConditions(
    store: Store<AdminState>,
    payload: UpdateTermsAndConditionPayload
  ) {
    try {
      store.commit('UPDATE_TERMS_AND_CONDITIONS_LOADING');
      const { data } = await adminApi.updateTermsAndConditions(payload);

      store.commit('UPDATE_TERMS_AND_CONDITIONS_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_TERMS_AND_CONDITIONS_ERROR', error);
    }
  },
  async deleteTermsAndConditions(store: Store<AdminState>, tncId: number) {
    try {
      store.commit('DELETE_TERMS_AND_CONDITIONS_LOADING');

      const { data } = await adminApi.deleteTermsAndConditions(tncId);

      store.commit('DELETE_TERMS_AND_CONDITIONS_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_TERMS_AND_CONDITIONS_ERROR', error);
    }
  },
  async setDefaultTermsAndConditions(store: Store<AdminState>, tncId: number) {
    try {
      store.commit('SET_DEFAULT_TERMS_AND_CONDITIONS_LOADING');

      const { data } = await adminApi.setDefaultTermsAndConditions(tncId);

      store.commit('SET_DEFAULT_TERMS_AND_CONDITIONS_SUCCESS', data);
    } catch (error) {
      store.commit('SET_DEFAULT_TERMS_AND_CONDITIONS_ERROR', error);
    }
  },
  async updateSubscriptionStatus(
    store: Store<AdminState>,
    payload: UpdateUserSubscriptionStatusPayload
  ) {
    try {
      store.commit('UPDATE_SUBSCRIPTION_STATUS_LOADING');

      await adminApi.updateSubscriptionStatus(payload);

      store.commit('UPDATE_SUBSCRIPTION_STATUS_SUCCESS');
    } catch (error) {
      store.commit('UPDATE_SUBSCRIPTION_STATUS_ERROR');
    }
  },

  async createNewGroup(
    store: Store<AdminState>,
    payload: CreateNewGroupRequestPayload
  ) {
    try {
      store.commit('CREATE_NEW_GROUP_LOADING');

      const { data } = await adminApi.createNewGroup(payload);

      store.commit('CREATE_NEW_GROUP_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('CREATE_NEW_GROUP_ERROR', error);
      throw error;
    }
  },

  async getGroups(store: Store<AdminState>, payload: FilteredGroupPayload) {
    try {
      store.commit('GET_GROUPS_LOADING');

      const { data } = await adminApi.getGroups(payload);

      store.commit('GET_GROUPS_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_GROUPS_ERROR', error);
      throw error;
    }
  },
  async getSpecificGroups(store: Store<AdminState>, payload: number[]) {
    try {
      store.commit('GET_SPECIFIC_GROUPS_LOADING');

      const { data } = await adminApi.getSpecificGroups(payload);

      store.commit('GET_SPECIFIC_GROUPS_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_SPECIFIC_GROUPS_ERROR', error);
      throw error;
    }
  },
  async getSimplifiedGroups(store: Store<AdminState>, payload: string) {
    try {
      store.commit('GET_SIMPLIFIED_GROUPS_LOADING');

      const { data } = await adminApi.getSimplifiedGroups(payload);

      store.commit('GET_SIMPLIFIED_GROUPS_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_SIMPLIFIED_GROUPS_ERROR', error);
      throw error;
    }
  },
  async getGroupsFromAttribute(
    store: Store<AdminState>,
    payload: FilteredGroupPayload
  ) {
    try {
      store.commit('GET_GROUPS_FROM_ATTRIBUTE_LOADING');

      const { data } = await adminApi.getGroups(payload);

      store.commit('GET_GROUPS_FROM_ATTRIBUTE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUPS_FROM_ATTRIBUTE_ERROR', error);
      throw error;
    }
  },

  async getGroupDetail(
    store: Store<AdminState>,
    { id, isMembers }: { id: number; isMembers: boolean }
  ) {
    try {
      store.commit('GET_GROUP_DETAIL_LOADING');

      const { data } = await adminApi.getGroupDetail(id, isMembers);

      store.commit('GET_GROUP_DETAIL_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_GROUP_DETAIL_ERROR', error);
      throw error;
    }
  },

  async getSubGroups(
    store: Store<AdminState>,
    { id, params }: { id: number; params: FilteredSubGroupPayload }
  ) {
    try {
      store.commit('GET_SUB_GROUPS_LOADING');

      const { data } = await adminApi.getSubGroups(id, params);

      store.commit('GET_SUB_GROUPS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_SUB_GROUPS_ERROR', error);
      throw error;
    }
  },

  async getMembers(
    store: Store<AdminState>,
    { groupId, options }: { groupId: number; options: any }
  ) {
    try {
      store.commit('GET_MEMBERS_LOADING');

      const { data } = await adminApi.getMembers(groupId, options);

      store.commit('GET_MEMBERS_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_MEMBERS_ERROR', error);
      throw error;
    }
  },

  async getParentGroupDetailByParentGroupId(
    store: Store<AdminState>,
    id: number
  ) {
    try {
      store.commit('GET_PARENT_GROUP_DETAIL_BY_PARENT_GROUP_ID_LOADING');

      const { data } = await adminApi.getParentGroupDetailByParentGroupId(id);

      store.commit('GET_PARENT_GROUP_DETAIL_BY_PARENT_GROUP_ID_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_PARENT_GROUP_DETAIL_BY_PARENT_GROUP_ID_ERROR', error);
      throw error;
    }
  },

  async getMasterGroupEmailDomains(store: Store<AdminState>, id: number) {
    try {
      store.commit('GET_MASTER_GROUP_EMAIL_DOMAINS_LOADING');

      const { data } = await adminApi.getMasterGroupEmailDomains(id);

      store.commit('GET_MASTER_GROUP_EMAIL_DOMAINS_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_MASTER_GROUP_EMAIL_DOMAINS_ERROR', error);
      throw error;
    }
  },

  async resetParentGroupDetailByParentGroupId(store: Store<AdminState>) {
    store.commit('RESET_GET_PARENT_GROUP_DETAIL_BY_PARENT_GROUP_ID');
  },

  async getParentGroupDetailByChildGroupId(
    store: Store<AdminState>,
    id: number
  ) {
    try {
      store.commit('GET_PARENT_GROUP_DETAIL_BY_CHILD_GROUP_ID_LOADING');

      const { data } = await adminApi.getParentGroupDetailByChildGroupId(id);

      store.commit('GET_PARENT_GROUP_DETAIL_BY_CHILD_GROUP_ID_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_PARENT_GROUP_DETAIL_BY_CHILD_GROUP_ID_ERROR', error);
      throw error;
    }
  },

  async getProtectedGroupTypes(store: Store<AdminState>) {
    try {
      store.commit('GET_PROTECTED_GROUP_TYPES_LOADING');

      const { data } = await adminApi.getProtectedGroupTypes();

      store.commit('GET_PROTECTED_GROUP_TYPES_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_PROTECTED_GROUP_TYPES_ERROR', error);
      throw error;
    }
  },

  async addMembersToGroup(
    store: Store<AdminState>,
    payload: AddMembersToGroupRequestPayload
  ) {
    try {
      store.commit('ADD_MEMBERS_TO_GROUP_LOADING');

      const { data } = await adminApi.addMembersToGroup(payload);

      store.commit('ADD_MEMBERS_TO_GROUP_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('ADD_MEMBERS_TO_GROUP_ERROR', error);
      throw error;
    }
  },

  async editGroupMember(
    store: Store<AdminState>,
    payload: UpdateGroupMemberRequestPayload
  ) {
    try {
      store.commit('EDIT_GROUP_MEMBER_LOADING');

      const { data } = await adminApi.editGroupMember(payload);

      store.commit('EDIT_GROUP_MEMBER_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('EDIT_GROUP_MEMBER_ERROR', error);
      throw error;
    }
  },

  async deleteGroupMember(
    store: Store<AdminState>,
    payload: DeleteGroupMemberRequestPayload
  ) {
    try {
      store.commit('DELETE_GROUP_MEMBER_LOADING');

      const { data } = await adminApi.deleteGroupMember(payload);

      store.commit('DELETE_GROUP_MEMBER_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('DELETE_GROUP_MEMBER_ERROR', error);
      throw error;
    }
  },

  async updateGroup(
    store: Store<AdminState>,
    payload: UpdateGroupRequestPayload
  ) {
    try {
      store.commit('UPDATE_GROUP_LOADING');

      const { data } = await adminApi.updateGroup(payload);

      store.commit('UPDATE_GROUP_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('UPDATE_GROUP_ERROR', error);
      throw error;
    }
  },

  async removeEmailDomain(
    store: Store<AdminState>,
    {
      groupId,
      emailDomain,
      notify
    }: {
      groupId: number;
      emailDomain: string | null;
      notify: boolean;
    }
  ) {
    try {
      store.commit('REMOVE_EMAIL_DOMAIN_LOADING');

      const { data } = await adminApi.removeEmailDomain(
        groupId,
        emailDomain,
        notify
      );

      store.commit('REMOVE_EMAIL_DOMAIN_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('REMOVE_EMAIL_DOMAIN_ERROR', error);
      throw error;
    }
  },

  async deleteGroup(store: Store<AdminState>, id: number) {
    try {
      store.commit('DELETE_GROUP_LOADING');

      const { data } = await adminApi.deleteGroup(id);

      store.commit('DELETE_GROUP_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('DELETE_GROUP_ERROR', error);
      throw error;
    }
  },

  async createGroupProduct(
    store: Store<AdminState>,
    payload: CreateGroupProductRequestPayload
  ) {
    try {
      store.commit('CREATE_GROUP_PRODUCT_LOADING');

      const { data } = await adminApi.createGroupProduct(payload);

      store.commit('CREATE_GROUP_PRODUCT_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('CREATE_GROUP_PRODUCT_ERROR', error);
      throw error;
    }
  },

  async getGroupProducts(
    store: Store<AdminState>,
    payload: GetGroupProductsPayload
  ) {
    try {
      store.commit('GET_GROUP_PRODUCTS_LOADING');

      const { data } = await adminApi.getGroupProducts(payload);

      store.commit('GET_GROUP_PRODUCTS_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_GROUP_PRODUCTS_ERROR', error);
      throw error;
    }
  },

  async updateGroupProduct(
    store: Store<AdminState>,
    payload: UpdateGroupProductRequestPayload
  ) {
    try {
      store.commit('UPDATE_GROUP_PRODUCT_LOADING');

      const { data } = await adminApi.updateGroupProduct(payload);

      store.commit('UPDATE_GROUP_PRODUCT_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('UPDATE_GROUP_PRODUCT_ERROR', error);
      throw error;
    }
  },

  async createGroupLicense(
    store: Store<AdminState>,
    payload: CreateGroupLicenseRequestPayload
  ) {
    try {
      store.commit('CREATE_GROUP_LICENSE_LOADING');

      const { data } = await adminApi.createGroupLicense(payload);

      store.commit('CREATE_GROUP_LICENSE_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('CREATE_GROUP_LICENSE_ERROR', error);
      throw error;
    }
  },

  async getGroupStats(store: Store<AdminState>, payload: boolean) {
    try {
      store.commit('GET_GROUP_STATS_LOADING');

      const { data } = await adminApi.getGroupStats(payload);

      store.commit('GET_GROUP_STATS_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_GROUP_STATS_ERROR', error);
      throw error;
    }
  },

  async getGroupLicenses(
    store: Store<AdminState>,
    payload: Partial<GetGroupLicensePayload>
  ) {
    try {
      store.commit('GET_GROUP_LICENSES_LOADING');

      const { data } = await adminApi.getGroupLicenses(payload);

      store.commit('GET_GROUP_PRODUCTS_NAME_LIST', data.groupProductsName);

      store.commit('GET_GROUP_LIST', data.groupsName);

      store.commit('GET_GROUP_LICENSES_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_GROUP_LICENSES_ERROR', error);
      throw error;
    }
  },
  async getThirdPartyUsers(
    store: Store<AdminState>,
    payload: GetThirdPartyUsersRequestPayload
  ) {
    try {
      store.commit('GET_THIRD_PARTY_USERS_LOADING');

      const { data } = await adminApi.getThirdPartyUserAccess(payload);

      store.commit('GET_THIRD_PARTY_USERS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_THIRD_PARTY_USERS_ERROR', error);
      throw error;
    }
  },

  async createThirdPartyUser(
    store: Store<AdminState>,
    payload: CreateThirdPartyUserPayload
  ) {
    try {
      store.commit('CREATE_THIRD_PARTY_USERS_LOADING');

      const data = await adminApi.createThirdPartyUser(payload);

      store.commit('CREATE_THIRD_PARTY_USERS_SUCCESS', data.data);
    } catch (error) {
      store.commit('CREATE_THIRD_PARTY_USERS_ERROR', error);
      throw error;
    }
  },

  async updateThirdPartyUser(
    store: Store<AdminState>,
    payload: UpdateThirdPartyUserPayload
  ) {
    try {
      store.commit('UPDATE_THIRD_PARTY_USER_LOADING');

      await adminApi.updateThirdPartyUser(payload);

      store.commit('UPDATE_THIRD_PARTY_USER_SUCCESS');
    } catch (error) {
      store.commit('UPDATE_THIRD_PARTY_USER_ERROR', error);
      throw error;
    }
  },

  async updateGroupLicense(
    store: Store<AdminState>,
    payload: UpdateGroupProductRequestPayload
  ) {
    try {
      store.commit('UPDATE_GROUP_LICENSE_LOADING');

      const { data } = await adminApi.updateGroupLicense(payload);

      store.commit('UPDATE_GROUP_LICENSE_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('UPDATE_GROUP_LICENSE_ERROR', error);
      throw error;
    }
  },

  async deleteGroupLicense(store: Store<AdminState>, id: number) {
    try {
      store.commit('DELETE_GROUP_LICENSE_LOADING');

      const { data } = await adminApi.deleteGroupLicense(id);

      store.commit('DELETE_GROUP_LICENSE_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('DELETE_GROUP_LICENSE_ERROR', error);
      throw error;
    }
  },

  async resendEmailNotificationToGroupMember(
    store: Store<AdminState>,
    payload: ResendEmailNotificationToGroupMemberPayload
  ) {
    try {
      store.commit('RESEND_EMAIL_NOTIFICATION_TO_GROUP_MEMBER_LOADING');

      const { data } = await adminApi.resendEmailNotificationToGroupMember(
        payload
      );

      store.commit('RESEND_EMAIL_NOTIFICATION_TO_GROUP_MEMBER_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('RESEND_EMAIL_NOTIFICATION_TO_GROUP_MEMBER_ERROR', error);
      throw error;
    }
  },

  async exportCsvGroupMembersList(
    store: Store<AdminState>,
    payload: ExportGroupMembersCsvPayload
  ) {
    try {
      store.commit('EXPORT_CSV_MEMBERS_LIST_LOADING');

      const result = await adminApi.exportCsvGroupMembersList(payload);

      const htmlObject = document.createElement('a');
      htmlObject.innerHTML = result.data;

      const res = htmlObject.firstChild;
      // @ts-ignore
      res.click();
      store.commit('EXPORT_CSV_MEMBERS_LIST_SUCCESS');
    } catch (error) {
      store.commit('EXPORT_CSV_MEMBERS_LIST_ERROR', error);
    }
  },

  async exportCsvDeleteGroupMembersList(
    store: Store<AdminState>,
    payload: ExportDeleteGroupMembersCsvPayload
  ) {
    try {
      store.commit('EXPORT_CSV_DELETE_MEMBERS_LIST_LOADING');

      const result = await adminApi.exportCsvDeleteGroupMembersList(payload);

      const htmlObject = document.createElement('a');
      htmlObject.innerHTML = result.data;

      const res = htmlObject.firstChild;
      // @ts-ignore
      res.click();
      store.commit('EXPORT_CSV_DELETE_MEMBERS_LIST_SUCCESS');
    } catch (error) {
      store.commit('EXPORT_CSV_DELETE_MEMBERS_LIST_ERROR', error);
    }
  },
  async uploadUserListFile(store: Store<AdminState>, file: File) {
    try {
      store.commit('UPLOAD_USER_LIST_FILE_LOADING');

      const { data } = await adminApi.uploadUserListFile(file);

      store.commit('UPLOAD_USER_LIST_FILE_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('UPLOAD_USER_LIST_FILE_ERROR', error);
      throw error;
    }
  },

  async validateSingleUserProduct(
    store: Store<AdminState>,
    payload: InviteUserToProductPayload
  ) {
    try {
      store.commit('VALIDATE_SINGLE_USER_PRODUCT_LOADING');

      const { data } = await adminApi.validateSingleUserProduct(payload);

      store.commit('VALIDATE_SINGLE_USER_PRODUCT_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('VALIDATE_SINGLE_USER_PRODUCT_ERROR', error);
      throw error;
    }
  },

  async getGroupAttributeOrderByGroupId(
    store: Store<AdminState>,
    groupId: number
  ) {
    try {
      store.commit('GET_GROUP_ATTRIBUTE_ORDER_LOADING');

      const { data } = await adminApi.getGroupAttributeOrderByGroupId(groupId);
      store.commit('GET_GROUP_ATTRIBUTE_ORDER_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_GROUP_ATTRIBUTE_ORDER_ERROR', error);
      throw error;
    }
  },

  async upsertGroupAttributeOrder(
    store: Store<AdminState>,
    payload: GroupAttributeOrder
  ) {
    try {
      store.commit('UPDATE_GROUP_ATTRIBUTE_ORDER_LOADING');

      const { data } = await adminApi.upsertGroupAttributeOrder(payload);
      store.commit('UPDATE_GROUP_ATTRIBUTE_ORDER_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('UPDATE_GROUP_ATTRIBUTE_ORDER_ERROR', error);
    }
  },
  async getGroupTypes(
    store: Store<AdminState>,
    payload?: GetGroupTypesPayload
  ) {
    try {
      store.commit('GET_GROUP_TYPES_LOADING');

      const { data } = await adminApi.getGroupTypes(payload);

      store.commit('GET_GROUP_TYPES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUP_TYPES_ERROR', error);
      throw error;
    }
  },
  async editGroupTypeById(store: Store<AdminState>, payload: GroupType) {
    try {
      store.commit('GROUP_TYPE_LOADING');

      await adminApi.editGroupTypeById(payload);

      store.commit('GROUP_TYPE_SUCCESS');
    } catch (error) {
      store.commit('GROUP_TYPE_ERROR', error);
      throw error;
    }
  },
  async createGroupType(store: Store<AdminState>, payload: string) {
    try {
      store.commit('GROUP_TYPE_LOADING');

      await adminApi.createGroupType(payload);

      store.commit('GROUP_TYPE_SUCCESS');
    } catch (error) {
      store.commit('GROUP_TYPE_ERROR', error);
    }
  },
  async getMembersCountByGroupId(store: Store<AdminState>, groupId: number) {
    try {
      store.commit('GET_MEMBERS_COUNT_BY_GROUPID_LOADING');

      const { data } = await adminApi.getMembersCountByGroupId(groupId);

      store.commit('GET_MEMBERS_COUNT_BY_GROUPID_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_MEMBERS_COUNT_BY_GROUPID_ERROR', error);
      throw error;
    }
  },
  async getUserByEmail(store: Store<AdminState>, email: string) {
    try {
      store.commit('GET_USER_BY_EMAIL_LOADING');

      const { data } = await adminApi.getUserByEmail(email);

      store.commit('GET_USER_BY_EMAIL_SUCCESS', data);

      return data;
    } catch (error) {
      store.commit('GET_USER_BY_EMAIL_ERROR', error);
    }
  },

  async getGroupLevelAttributeTypes(store: Store<AdminState>) {
    try {
      store.commit('GET_GROUP_LEVEL_ATTRIBUTE_TYPES_LOADING');

      const { data } = await adminApi.getGroupLevelAttributeTypes();

      store.commit('GET_GROUP_LEVEL_ATTRIBUTE_TYPES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUP_LEVEL_ATTRIBUTE_TYPES_ERROR', error);
      throw error;
    }
  },

  async getGroupLevelAttributeValuesByGroupId(
    store: Store<AdminState>,
    groupId: number
  ) {
    try {
      store.commit('GET_GROUP_LEVEL_ATTRIBUTE_VALUES_LOADING');
      const { data } = await adminApi.getGroupLevelAttributeValuesByGroupId(
        groupId
      );
      store.commit('GET_GROUP_LEVEL_ATTRIBUTE_VALUES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUP_LEVEL_ATTRIBUTE_VALUES_ERROR', error);
      throw error;
    }
  },

  async createGroupLevelAttributesValues(
    store: Store<AdminState>,
    payload: GroupLevelAttributeValuePayload
  ) {
    try {
      store.commit('CREATE_GROUP_LEVEL_ATTRIBUTE_VALUES_LOADING');

      const { data } = await adminApi.createGroupLevelAttributesValues(payload);

      store.commit('CREATE_GROUP_LEVEL_ATTRIBUTE_VALUES_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_GROUP_LEVEL_ATTRIBUTE_VALUES_ERROR', error);
      throw error;
    }
  },

  async updateGroupLevelAttributeValues(
    store: Store<AdminState>,
    payload: GroupLevelAttributeValuePayload
  ) {
    try {
      store.commit('UPDATE_GROUP_LEVEL_ATTRIBUTE_VALUES_LOADING');

      const { data } = await adminApi.updateGroupLevelAttributeValues(payload);

      store.commit('UPDATE_GROUP_LEVEL_ATTRIBUTE_VALUES_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_GROUP_LEVEL_ATTRIBUTE_VALUES_ERROR', error);
      throw error;
    }
  },

  async getGroupUserAttributeTypes(store: Store<AdminState>) {
    try {
      store.commit('GET_GROUP_USER_ATTRIBUTE_TYPES_LOADING');

      const { data } = await adminApi.getGroupUserAttributeTypes();
      store.commit('GET_GROUP_USER_ATTRIBUTE_TYPES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUP_USER_ATTRIBUTE_TYPES_ERROR', error);
      throw error;
    }
  },

  async getGroupUserAttributeById(
    store: Store<AdminState>,
    attributeId: number
  ) {
    try {
      store.commit('GET_GROUP_USER_ATTRIBUTE_LOADING');

      const { data } = await adminApi.getGroupUserAttributeWithGroupCountById(
        attributeId
      );
      store.commit('GET_GROUP_USER_ATTRIBUTE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUP_USER_ATTRIBUTE_ERROR', error);
      throw error;
    }
  },

  async verifyGroupUserAttribute(
    store: Store<AdminState>,
    attributeLabel: string
  ) {
    try {
      store.commit('VERIFY_GROUP_USER_ATTRIBUTE_LOADING');

      const { data } = await adminApi.verifyGroupUserAttribute(attributeLabel);
      store.commit('VERIFY_GROUP_USER_ATTRIBUTE_SUCCESS', data);
    } catch (error) {
      store.commit('VERIFY_GROUP_USER_ATTRIBUTE_ERROR', error);
      throw error;
    }
  },

  async createGroupUserAttribute(
    store: Store<AdminState>,
    payload: GroupUserAttributePayload
  ) {
    try {
      store.commit('CREATE_GROUP_USER_ATTRIBUTE_LOADING');

      const { data } = await adminApi.createGroupUserAttribute(payload);
      store.commit('CREATE_GROUP_USER_ATTRIBUTE_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_GROUP_USER_ATTRIBUTE_ERROR', error);
      throw error;
    }
  },

  async updateGroupsWithAttribute(
    store: Store<AdminState>,
    payload: UpdateGroupsWithAttributePayload
  ): Promise<void> {
    try {
      store.commit('UPDATE_GROUPS_WITH_ATTRIBUTE_LOADING');

      const { data } = await adminApi.updateGroupsWithAttribute(payload);
      store.commit('UPDATE_GROUPS_WITH_ATTRIBUTE_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_GROUPS_WITH_ATTRIBUTE_ERROR', error);
      throw error;
    }
  },

  async getSpecsByAttributeId(
    store: Store<AdminState>,
    attributeId: number
  ): Promise<void> {
    try {
      store.commit('GET_SPECS_BY_ATTRIBUTE_ID_LOADING');

      const { data } = await adminApi.getSpecsByAttributeId(attributeId);
      store.commit('GET_SPECS_BY_ATTRIBUTE_ID_SUCCESS', data);
    } catch (error) {
      store.commit('GET_SPECS_BY_ATTRIBUTE_ID_ERROR', error);
      throw error;
    }
  },

  async getGroupUserAttributes(store: Store<AdminState>) {
    try {
      store.commit('GET_GROUP_USER_ATTRIBUTES_LOADING');

      const { data } = await adminApi.getGroupUserAttributes();
      store.commit('GET_GROUP_USER_ATTRIBUTES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUP_USER_ATTRIBUTES_ERROR', error);
      throw error;
    }
  },

  async updateGroupUserAttribute(
    store: Store<AdminState>,
    payload: UpdateGroupsUserAttributePayload
  ): Promise<void> {
    try {
      store.commit('UPDATE_GROUP_USER_ATTRIBUTE_LOADING');

      const { data } = await adminApi.updateGroupUserAttribute(payload);
      store.commit('UPDATE_GROUP_USER_ATTRIBUTE_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_GROUP_USER_ATTRIBUTE_ERROR', error);
      throw error;
    }
  },

  async deleteGroupUserAttribute(
    store: Store<AdminState>,
    attributeId: number
  ) {
    try {
      store.commit('DELETE_GROUP_USER_ATTRIBUTE_LOADING');

      await adminApi.deleteGroupUserAttribute(attributeId);
      store.commit('DELETE_GROUP_USER_ATTRIBUTE_SUCCESS');
    } catch (error) {
      store.commit('DELETE_GROUP_USER_ATTRIBUTE_ERROR', error);
      throw error;
    }
  },

  async getCurrentUserAttributes(store: Store<AdminState>) {
    try {
      store.commit('GET_CURRENT_USER_ATTRIBUTES_LOADING');

      const { data } = await adminApi.getCurrentUserAttributes();
      store.commit('GET_CURRENT_USER_ATTRIBUTES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_CURRENT_USER_ATTRIBUTES_ERROR', error);
      throw error;
    }
  },

  async updateCurrentUserAttributeValues(
    store: Store<AdminState>,
    payload: BulkUpdateMemberGroupUserAttributeValuePayload
  ): Promise<void> {
    try {
      store.commit('UPDATE_CURRENT_USER_ATTRIBUTE_VALUES_LOADING');

      const { data } = await adminApi.updateCurrentUserAttributeValues(payload);
      store.commit('UPDATE_CURRENT_USER_ATTRIBUTE_VALUES_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_CURRENT_USER_ATTRIBUTE_VALUES_ERROR', error);
      throw error;
    }
  },

  async createGroupTemplate(
    store: Store<AdminState>,
    payload: GroupTemplatePayload
  ) {
    try {
      store.commit('CREATE_GROUP_TEMPLATE_LOADING');

      const { data } = await adminApi.createGroupTemplate(payload);
      store.commit('CREATE_GROUP_TEMPLATE_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_GROUP_TEMPLATE_ERROR', error);
      throw error;
    }
  },

  async updateGroupTemplate(
    store: Store<AdminState>,
    payload: GroupTemplatePayload
  ) {
    try {
      store.commit('UPDATE_GROUP_TEMPLATE_LOADING');

      const { data } = await adminApi.updateGroupTemplate(payload);
      store.commit('UPDATE_GROUP_TEMPLATE_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_GROUP_TEMPLATE_ERROR', error);
      throw error;
    }
  },

  async deleteGroupTemplate(store: Store<AdminState>, templateId: number) {
    try {
      store.commit('DELETE_GROUP_TEMPLATE_LOADING');
      const { data } = await adminApi.deleteGroupTemplate(templateId);
      store.commit('DELETE_GROUP_TEMPLATE_SUCCESS', data);
    } catch (error) {
      store.commit('DELETE_GROUP_TEMPLATE_ERROR', error);
      throw error;
    }
  },

  async getGroupTemplates(
    store: Store<AdminState>,
    payload: FilteredGroupTemplatesPayload
  ) {
    try {
      store.commit('GET_GROUP_TEMPLATE_LOADING');

      const { data } = await adminApi.getGroupTemplates(payload);
      store.commit('GET_GROUP_TEMPLATE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUP_TEMPLATE_ERROR', error);
      throw error;
    }
  },

  async verifyGroupTemplate(store: Store<AdminState>, name: string) {
    try {
      store.commit('CHECK_GROUP_TEMPLATE_LOADING');

      const { data } = await adminApi.verifyGroupTemplate(name);
      store.commit('CHECK_GROUP_TEMPLATE_SUCCESS', data);
    } catch (error) {
      store.commit('CHECK_GROUP_TEMPLATE_ERROR', error);
      throw error;
    }
  },

  async getUserByAdmin(store: Store<AdminState>, userId: number) {
    try {
      store.commit('GET_USER_LOADING');

      const { data } = await adminApi.getUserByAdmin(userId);
      store.commit('GET_USER_SUCCESS', data);
    } catch (error) {
      store.commit('GET_USER_ERROR', error);
    }
  },

  async updateUserByAdmin(
    store: Store<AdminState>,
    { user, userProfile }: { user: any; userProfile: ProfileUpdatePayload }
  ) {
    try {
      store.commit('UPDATE_USER_LOADING');

      const { data } = await adminApi.updateUserByAdmin(user, userProfile);
      store.commit('UPDATE_USER_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_USER_ERROR', error);
    }
  },

  async updateUserProfileImageByAdmin(
    store: Store<ProfileState>,
    { user, profileImageUrl }: { user: any; profileImageUrl: string }
  ) {
    try {
      store.commit('UPDATE_PROFILE_IMAGE_LOADING');

      const { data } = await adminApi.updateUserProfileImageByAdmin(
        user,
        profileImageUrl
      );
      store.commit('UPDATE_PROFILE_IMAGE_SUCCESS', data);
    } catch (error) {
      store.commit('UPDATE_PROFILE_IMAGE_ERROR', error);
    }
  },

  async getUploadImageSignedUrl(
    store: Store<AdminState>,
    payload: GetUploadImageSignedUrlPayload
  ) {
    try {
      store.commit('GET_GROUP_USER_ATTRIBUTE_UPLOAD_IMAGE_SIGNED_URL_LOADING');

      const { data } = await adminApi.getUploadImageSignedUrl(payload);
      store.commit(
        'GET_GROUP_USER_ATTRIBUTE_UPLOAD_IMAGE_SIGNED_URL_SUCCESS',
        data
      );
      return data;
    } catch (error) {
      store.commit(
        'GET_GROUP_USER_ATTRIBUTE_UPLOAD_IMAGE_SIGNED_URL_ERROR',
        error
      );
    }
  },

  /**
   * NOTE:
   * This action COMMITS data to ONE UNIFIED STATE and also RETURNS the data.
   *
   * Reason behind this application is because there might be
   * multiple triggers for this action simultaneously.
   *
   * When you have multiple watchers all watching for the same state,
   * your individual watcher callback might not work as intended.
   *
   * Only read data from the state if YOU ARE SURE
   * that there will be no simultaneous triggers.
   *
   * Else, "await"ing the data directly in your trigger
   * is the only way to ensure the integrity of the response.
   */
  async getCurrentUserUploadImageSignedUrl(
    store: Store<AdminState>,
    payload: GetUploadImageSignedUrlPayload
  ) {
    try {
      store.commit('GET_CURRENT_USER_UPLOAD_IMAGE_SIGNED_URL_LOADING');

      const { data } = await adminApi.getCurrentUserUploadImageSignedUrl(
        payload
      );
      store.commit('GET_CURRENT_USER_UPLOAD_IMAGE_SIGNED_URL_SUCCESS', data);
      return data;
    } catch (error) {
      store.commit('GET_CURRENT_USER_UPLOAD_IMAGE_SIGNED_URL_ERROR', error);
    }
  },

  async getUserGroupsByUserId(store: Store<AdminState>, userId: number) {
    try {
      store.commit('GET_USER_GROUPS_LOADING');

      const { data } = await adminApi.getUserGroupsByUserId(userId);
      store.commit('GET_USER_GROUPS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_USER_GROUPS_ERROR', error);
      throw error;
    }
  },

  async getGroupUserAttributeList(
    store: Store<AdminState>,
    payload: GetGroupUserAttributeListPayload
  ): Promise<void> {
    try {
      store.commit('GET_GROUP_USER_ATTRIBUTE_LIST_LOADING');

      const { data } = await adminApi.getGroupUserAttributeList(payload);
      store.commit('GET_GROUP_USER_ATTRIBUTE_LIST_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUP_USER_ATTRIBUTE_LIST_ERROR', error);
      throw error;
    }
  },

  async getCountries(store: Store<AdminState>, payload?: AddressFilterOption) {
    try {
      store.commit('GET_ALL_COUNTRIES_LOADING');
      const { data } = await adminApi.getCountries(payload);
      store.commit('GET_ALL_COUNTRIES_SUCCESS', data);
    } catch (error) {
      store.commit('GET_ALL_COUNTRIES_ERROR', error);
    }
  },

  async getStatesByCountryId(store: Store<AdminState>, countryId: number) {
    try {
      store.commit('GET_STATES_BY_COUNTRY_ID_LOADING');
      const { data } = await adminApi.getStatesByCountryId(countryId);
      store.commit('GET_STATES_BY_COUNTRY_ID_SUCCESS', data);
    } catch (error) {
      store.commit('GET_STATES_BY_COUNTRY_ID_ERROR', error);
    }
  },

  async getCountryByCountryId(store: Store<AdminState>, countryId: number) {
    try {
      store.commit('GET_COUNTRY_BY_ID_LOADING');
      const { data } = await adminApi.getCountryByCountryId(countryId);
      store.commit('GET_COUNTRY_BY_ID_SUCCESS', data);
    } catch (error) {
      store.commit('GET_COUNTRY_BY_ID_ERROR', error);
    }
  },

  /**
   * user attribute template actions
   */
  async getGroupUserAttributeTemplates(
    store: Store<AdminState>,
    payload: GetGroupUserAttributeTemplatePayload
  ) {
    try {
      store.commit('GET_GROUP_USER_ATTRIBUTE_TEMPLATE_LOADING');
      const { data } = await adminApi.getGroupUserAttributeTemplates(payload);
      store.commit('GET_GROUP_USER_ATTRIBUTE_TEMPLATE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUP_USER_ATTRIBUTE_TEMPLATE_ERROR', error);
      throw error;
    }
  },
  async verifyGroupUserAttributeTemplateName(
    store: Store<AdminState>,
    templateName: string
  ) {
    try {
      store.commit('VERIFY_GROUP_USER_ATTRIBUTE_TEMPLATE_LOADING');
      const { data } = await adminApi.verifyGroupUserAttributeTemplateName(
        templateName
      );
      store.commit('VERIFY_GROUP_USER_ATTRIBUTE_TEMPLATE_SUCCESS', data);
    } catch (error) {
      store.commit('VERIFY_GROUP_USER_ATTRIBUTE_TEMPLATE_ERROR', error);
      throw error;
    }
  },
  async createNewGroupUserAttributeTemplate(
    store: Store<AdminState>,
    payload: NewGroupUserAttributeTemplatePayload
  ) {
    try {
      store.commit('CREATE_GROUP_USER_ATTRIBUTE_TEMPLATE_LOADING');
      const { data } = await adminApi.createNewGroupUserAttributeTemplate(
        payload
      );
      store.commit('CREATE_GROUP_USER_ATTRIBUTE_TEMPLATE_SUCCESS', data);
    } catch (error) {
      store.commit('CREATE_GROUP_USER_ATTRIBUTE_TEMPLATE_ERROR', error);
      throw error;
    }
  },
  async deleteGroupUserAttributeTemplate(store: Store<AdminState>, id: number) {
    try {
      store.commit('DELETE_GROUP_USER_ATTRIBUTE_TEMPLATE_LOADING');
      const { data } = await adminApi.deleteGroupUserAttributeTemplate(id);
      store.commit('DELETE_GROUP_USER_ATTRIBUTE_TEMPLATE_SUCCESS');
    } catch (error) {
      store.commit('DELETE_GROUP_USER_ATTRIBUTE_TEMPLATE_ERROR', error);
      throw error;
    }
  },
  async updateGroupUserAttributeTemplate(
    store: Store<AdminState>,
    payload: UpdateGroupUserAttributeTemplatePayload
  ) {
    try {
      store.commit('UPDATE_GROUP_USER_ATTRIBUTE_TEMPLATE_LOADING');
      const { data } = await adminApi.updateGroupUserAttributeTemplate(payload);
      store.commit('UPDATE_GROUP_USER_ATTRIBUTE_TEMPLATE_SUCCESS');
    } catch (error) {
      store.commit('UPDATE_GROUP_USER_ATTRIBUTE_TEMPLATE_ERROR', error);
      throw error;
    }
  },

  async getFilteredUserList(
    store: Store<AdminState>,
    params: GetFilteredUserListPayload
  ): Promise<void> {
    try {
      store.commit('GET_FILTERED_USERS_LOADING');

      const { data } = await adminApi.getFilteredUserList(params);

      store.commit('GET_FILTERED_USERS_SUCCESS', data);
    } catch (error) {
      store.commit('GET_FILTERED_USERS_ERROR', error);
    }
  },

  async getGroupsByNameAndType(
    store: Store<AdminState>,
    params: { groupName: string; groupType: string }
  ): Promise<void> {
    try {
      store.commit('GET_GROUPS_BY_NAME_AND_TYPE_LOADING');

      const { data } = await adminApi.getGroupsByNameAndType(params);

      store.commit('GET_GROUPS_BY_NAME_AND_TYPE_SUCCESS', data);
    } catch (error) {
      store.commit('GET_GROUPS_BY_NAME_AND_TYPE_ERROR', error);
    }
  }
};
